import logo from './logo_small.png';
import top from './firstlogo.jpg';
import map from './map.png';
import crs from './crs.pdf';
import './App.css';
import React, { useRef } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';


function App() {

  const ourFirm = useRef()
  const ourHome = useRef()
  const ourApproach = useRef()
  const ourOffering = useRef()
  const ourContact = useRef()
  const [count, setCount] = useState("Bahman Mossavar-Rahmani is a founding member of UAS Asset Management and serves as its Chief Investment Officer and Senior Portfolio Manager. He has been chiefly responsible for the development and execution of UAS' investment philosophy and approach. Prior to organizing UAS, Bahman was a founding member of Connecticut Securities, a Hartford firm specializing in fixed-income investments. He has started, acquired or advised companies in the securities, consumer information, distribution, and business equipment industries. Bahman started his career as a financial journalist, prior to roles in commercial banking and fixed income securities. Bahman attended Harvard College and the Harvard Graduate School of Business Administration. He and his wife reside in Greenwich, CT.");

  function linkHome() {
    ourHome.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
  }
  function linkFirm() {
    ourFirm.current.scrollIntoView({behavior: "smooth", inline: "end" })    
  }
  function linkApproach() {
    ourApproach.current.scrollIntoView({behavior: "smooth", inline: "nearest"})
  }
  function linkOffering() {
    ourOffering.current.scrollIntoView({behavior: "smooth", inline: "nearest"})
  }
  function linkContact() {
    ourContact.current.scrollIntoView({behavior: "smooth", inline: "nearest"})
  }




  return (
   
      
  
    <div className="App">


            <style>
              @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Roboto:ital,wght@0,300;1,100&display=swap');
            </style>

<header className="App-header">

  

  <div className="row">

    <div className="column">
      <img src={logo} alt="logo" onClick={linkHome} loading="lazy" />
   </div>
    

    <div className="row_sets">
      <div className="link_home">
          <div className="link" onClick={linkHome}>HOME</div>
     </div>
      
      <div className="link_firm">
        <div className="link" onClick={linkFirm}>OUR FIRM</div>   
      </div>
     
   

      <div className="link_offering">
        <div className="link" onClick={linkOffering}>OFFERING</div>       
      </div>

      <div className="link_offering">
        <div className="link" onClick={linkApproach}>OUR APPROACH</div>       
      </div>

     
      
      <div className="link_form">
        <div className="link"><a className="link" href={crs}>FORM CRS</a></div>  
      </div>
      
      <div className="link_contact">
      <div className="link" onClick={linkContact}>CONTACT US</div>
      </div>


    </div>
      
</div>

</header>


<div class="wrapper">
</div>



  <div className="first_logo" ref={ourHome} name="first_logo">
  <img src={top} className="logostyle" alt="logo" />
  </div>



      <div className="intro">

        <p>

      <b className="intro_green">At UAS Asset Management,</b> we have applied this philosophy to our investment and wealth management business for 35 years. In serving the needs of individuals, families and institutional investors, our professionals are keenly focused on investing in global businesses with superior economics, proven management, and strong brand value – we believe those characteristics lead to enduring advantage. 
      
      We build portfolios of significant publicly traded global companies whose range of businesses provides inherent diversification and whose scale and tenure demonstrate durable competitive advantage. We invest when we believe prices are reasonable and maintain positions only when all advantages remain in place. Our high conviction results in relatively concentrated portfolios. <span ref={ourFirm}></span>
      </p>
      
      </div>

 

      <div className="left"> 

      <div className="main_light_left">
      <p>OUR FIRM</p>
      <p className ="h4"> Our investors appreciate access to our investment team and take comfort from regular dialogue with the team entrusted with their assets.
      </p>
      </div>
      <div className="main_light2">
      <hr></hr>
      UAS Asset Management has been entrusted to manage individual and institutional portfolios. The firm has followed the same investment philosophy for over thirty years. We consistently apply thorough fundamental research with dispassionate analytics, independent thinking, and discipline, while continuously learning and adapting.

      <p>
      As investment managers, we take responsibility for our clients’ assets as fiduciaries, always placing their interests first. We keep our team small and focused on core competencies and choosing to partner with world-class providers for all support functions. We work only with independent qualified custodians for the safekeeping of our clients’ assets.
      </p>

      </div>

      </div>

      <div className="left">

      <div className="main_light_left_buttons">


      <div>
          
            
            <button className="link_names" onClick={() => setCount("Bahman Mossavar-Rahmani is a founding member of UAS Asset Management and serves as its Chief Investment Officer and Senior Portfolio Manager. He has been chiefly responsible for the development and execution of UAS' investment philosophy and approach. Prior to organizing UAS, Bahman was a founding member of Connecticut Securities, a Hartford firm specializing in fixed-income investments. He has started, acquired or advised companies in the securities, consumer information, distribution, and business equipment industries. Bahman started his career as a financial journalist, prior to roles in commercial banking and fixed income securities. Bahman attended Harvard College and the Harvard Graduate School of Business Administration. He and his wife reside in Greenwich, CT. ")}>
              Bahman Mossavar-Rahmani
            </button>
      <p>
            <button className="link_names" onClick={() => setCount("Ali joined UAS as a Managing Director in 2003. He heads research for the firm, is a member of the firm’s investment committee, and serves on the firm’s board of directors. Prior to joining UAS, Ali spent over 15 years in the field of global strategy consulting, acting in various leadership positions with a number of research and consulting firms. He has advised senior executives among various industries, including health care, consumer products, and financial services, with a particular focus on enhancing shareholder value. In addition to his consulting background, Ali has direct management experience with several professional service organizations and has been a founding member of startups in both high-technology and business services areas. Ali earned his PhD in Systems Sciences from the Wharton School of the University of Pennsylvania. He also holds an MS in Operations Research from Stanford University, and a BS in Industrial Engineering (summa cum laude) from Syracuse University. He is the author of several articles and co-editor of the book Internal Markets: Bringing the Power of Free Enterprise inside Your Organizations, published by John Wiley & Sons. Ali is married with three children and enjoys skiing, soccer, and squash. He lives in Manhattan.")}>
              Ali Granmayeh
              </button>

              </p>
      <p>
            <button className="link_names" onClick={() => setCount("Jim manages client services and is responsible for the day-to-day operation of the firm. He joined UAS in May 2006. Jim has logged over 35 years of operations experience in the financial services industry with such firms as Prudential Securities, Munich Re Asset Management, and DLIBJ Asset Management. He served as Vice President and Director of Operations for Donaldson Lufkin Jenrette Asset Management (now part of Credit Suisse Asset Management) for 20 years where he developed and implemented the firm’s operational infrastructure and was directly involved in the creation of operational processes for new products. Jim also served as the Chief Compliance Officer at DLIBJ Asset Management for two years. Jim earned his degree from Villanova University and resides in New Jersey with his wife and daughter.")}>
              James J. Lisanti
            </button>

            </p>

          </div>

      </div>
      <div className="main_light2">
      <hr></hr>
      OUR EXECUTIVE TEAM

      <div>
      <p>{count}<span ref={ourApproach}></span></p>

      </div>

      </div>


      </div>

      <div className="left">

      <div className="main_light3_left">
      <p>OUR APPROACH</p>
      <p className="h4">We approach investing from the perspective that preserving wealth is essential to building it.
      </p>
      </div>

      <div className="main_light3">
      <hr></hr>
      <p>
      Our assessment of a company’s prospects and value relies on a variety of both quantitative and qualitative factors. We look for great companies whose business model we can understand and whose future earnings we believe we can reasonably estimate.  Our high conviction results in concentrated portfolios of distinctive companies whose brand value, diversified holdings, proven management and strong financial profile set them apart as leaders.
      </p>
      Importantly, we balance our fundamental analyses with a view toward long-term global trends and secular themes, seeking companies where we believe demand for their products and services is likely to grow based on positive demographic trends and increasing economic development.
      <p>
      Risk management goes hand-in-hand with portfolio construction.  Conventional wisdom equates risk with monthly, or even daily, volatility of prices of securities. We, on the other hand, regard risk as the likelihood of permanent loss of capital. Viewed in that light, we are focused on inherent value of our holdings not on the daily fluctuations of their prices. By investing only in publicly traded securities we further manage risk by maintaining highly liquid portfolios.
      </p>
      Consistent application of our investment philosophy enables clients to properly evaluate the efficacy of our investment strategy to achieve their objectives.
      </div>
      </div>


      <div className="left">

      <div className="main_light_left">
        <p>OUR PHILOSOPHY</p>
        
      <p className="h4">Prudent investing centers on preserving and growing wealth for generations - as such we invest in companies who think likewise.
      </p>

      </div>
      <div className="main_light2"><hr></hr>
      The philosophy governing our approach to investing is straightforward: we want to own superior companies with good management at reasonable prices and remain owners as long as all three criteria are met. As such, our research is aimed at defining and assessing each of these aspects: what are superior economics, reasonable prices and good management and which companies possess them.
      <p>
      While our philosophy is simple and understandable, successful implementation is anything but. Not only does it require dispassionate analysis and research in assessing each potential investment, but, and perhaps more importantly, it requires patience, discipline and courage.
      </p>
      We are proud of the quality of our research and analysis, but we believe that the real differentiator for UAS lies in our independent thinking and discipline in acting on well-reasoned convictions — going against the prevailing trend, takes a particular <span ref={ourOffering}></span>temperament and courage, honed by a disciplined process.
      </div>
      </div>

      <div className="left"  >

        <div className="main_light2_left" >

      <p>OUR OFFERING</p>

      </div>

      <div className="main_light">
      <hr></hr>
      Our primary service is management of equity portfolios in separately managed accounts (SMAs) for individuals, groups and institutions. In addition, we manage balanced accounts for clients who need current income by investing an appropriate portion of the portfolio in US Government bonds and investment grade corporate bonds.
      <p>
      We manage a variety of retirement accounts, including IRAs, 401(k)s and pension plans. In addition, we accept accounts for trusts, foundations, endowments, corporations, associations, and other pools of capital. In all cases, we coordinate our efforts with accountants, pension administrators, legal advisers, and other professionals assisting our clients.
      </p>
      For qualified investors, UAS offers an alternative investment strategy, Kitty Hawk, with a performance-based fee structure.
      </div>
      </div>

      <div className="left">
      <div className="main_light2_left">
        
      <p>OUR CLIENT FOCUS</p>
      <p className="h5">Our clients are our business. We strive to build enduring relationships by understanding our clients’ needs and objectives, ensuring our clients know what they can expect from our team.</p>
      </div>
      <div className="main_light">
      <hr></hr>
      We begin every client engagement with an open and honest dialogue designed to accomplish two goals: <br></br>
      <br></br>
      1. To gain an understanding of what the client’s needs and objectives are. 
      <br></br><br></br>2. For the client to have a clear understanding of how we approach investing and the stewardship of their assets. We only embark on a new relationship when <span ref={ourContact}></span>our approach and strategies are aligned with and can be effective in meeting our client’s financial goals.


      </div>
      </div>

      <div className="left" >

      <div className="main_light3_left">
        
      <b className="end_green">CONTACT US</b>
      <p>For further information</p>
      </div>
      <div className="main_light3">
      <hr></hr>
      UAS Asset Management
      <p>655 Third Avenue</p>
   <p>29th Floor</p>
      <p>New York, NY 10017</p>
      <p>212-983-5822</p>
      <hr></hr>

      </div>

      </div>

      <div className="bottomText">
      

      <div class="right_align">


      {/*
      <Link className="link" to='/bahmanmossavar-rahmani'>Bahman Mossavar-Rahmani</Link>
      <Link className="link" to='/ali-granmayeh'>Ali Granmayeh</Link>
      <Link className="link" to='/jim-lisanti'>James J. Lisanti</Link>

      */}
      <p><div className="link_CRS"><a className="link" href={crs}>FORM CRS</a></div> </p>

<p>
      <a className="link"><small>© Copyright 2023 UAS Asset Management. All Rights Reserved </small></a>
      </p>
      <small>   <a className="link" href="BahmanMossavarRahmani.html"><small>Bahman Mossavar-Rahmani</small></a>    </small> 
                <a className="link" href="AG.html"><small>Ali Granmayeh</small></a>
                <a className="link" href="JL.html"><small>James J. Lisanti</small></a>
                <a className="link" href="Offering.html"><small>Our Offering</small></a>
                <a className="link" href="Philosophy.html"><small>Our Philosophy</small></a>
  



      </div>


      </div>


          </div>

  );
}

const Header = () => {


  const ourFirm = useRef()
  const ourHome = useRef()
  const ourApproach = useRef()
  const ourOffering = useRef()
  const ourContact = useRef()
  const [count, setCount] = useState("Bahman Mossavar-Rahmani is a founding member of UAS Asset Management and serves as its Chief Investment Officer and Senior Portfolio Manager. He has been chiefly responsible for development and execution of UAS investment philosophy and approach. Prior to organizing UAS, Bahman was a founding member of Connecticut Securities, a Hartford firm specializing in fixed-income investments. He has started, acquired or advised companies in the securities, consumer information, distribution, and business equipment industries. Bahman started his career as a financial journalist, prior to roles in commercial banking and fixed income securities. Bahman attended Harvard College and the Harvard Graduate School of Business Administration. Bahman Mossavar-Rahmani and his wife reside in Greenwich, CT.");

  function linkHome() {
    ourHome.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
  }
  function linkFirm() {
    ourFirm.current.scrollIntoView({behavior: "smooth", inline: "end" })    
  }
  function linkApproach() {
    ourApproach.current.scrollIntoView({behavior: "smooth", inline: "nearest"})
  }
  function linkOffering() {
    ourOffering.current.scrollIntoView({behavior: "smooth", inline: "nearest"})
  }
  function linkContact() {
    ourContact.current.scrollIntoView({behavior: "smooth", inline: "nearest"})
  }

	return (

		<header className="App-header">

  <div className="row">

    <div className="column">
      <img src={logo} alt="logo" loading="lazy" id="uas_logo"></img>
     </div>
    

    <div className="row_sets">
    <div className="link_home">
    <Link className="link" to='/'>Home</Link>
    </div>
      <div className="link_firm">
      <Link className="link" to='/'>Our Firm</Link>
        
      </div>
      <div className="link_offering">
      <Link className="link" to='/'>Approach</Link>
        
      </div>
      <div className="link_offering">
      <Link className="link" to='/'>Offering</Link>    
      </div>
      <div className="link_form">
     {/* <div className="link"><a className="link" href={crs}>FORM CRS</a></div>*/}
        
        <div className="link_CRS"><a className="link" href={crs}>FORM CRS</a></div>
        
      </div>
      
      <div className="link_contact">
      <Link className="link" to='/'>Contact</Link>
      
      </div>
      </div>
      
</div>

</header>


	)
};


const BMR = () => {
	return (
		<div>
				<Header/>

        <div className="intro"></div>
        <div className="main_light_left"></div>
        <div className="main_light_left">
        </div>
       <p></p>  <p></p> 
          <div className="intro">
          <b>	Bahman Mossavar-Rahmani</b>
          </div>

        <div className="intro">
          
        
        
      Bahman Mossavar-Rahmani is a founding member of UAS Asset Management and serves as its Chief Investment Officer and Senior Portfolio Manager. He has been chiefly responsible for the development and execution of UAS' investment philosophy and approach. Prior to organizing UAS, Bahman was a founding member of Connecticut Securities, a Hartford firm specializing in fixed-income investments. He has started, acquired or advised companies in the securities, consumer information, distribution, and business equipment industries. Bahman started his career as a financial journalist, prior to roles in commercial banking and fixed income securities. Bahman attended Harvard College and the Harvard Graduate School of Business Administration. He and his wife reside in Greenwich, CT.
        
        {/*Prior to organizing UAS, Bahman was a founding member of Connecticut Securities, a Hartford firm specializing in fixed-income investments. He has started, acquired or advised companies in the securities, consumer information, distribution, and business equipment industries. Bahman started his career as a financial journalist, prior to roles in commercial banking and fixed income securities. Bahman attended Harvard College and the Harvard Graduate School of Business Administration. Bahman Mossavar-Rahmani and his wife reside in Greenwich, CT. */}

      
        </div>

        <div className="copyright">© Copyright 2023 UAS Asset Management. All Rights Reserved

 

</div>
        
  	</div>
	)
};
const AG = () => {return (
  <div>
      <Header/>

      <div className="intro"></div>
      <div className="main_light_left"></div>
      <div className="main_light_left">
      </div>
     <p></p>  <p></p> 
        <div className="intro">
        <b>Ali Granmayeh</b>
        </div>

      <div className="intro">
        
      
      
      Ali joined UAS as a Managing Director in 2003. He heads research for the firm, is a member of the firm’s investment committee, and serves on the firm’s board of directors. Prior to joining UAS, Ali spent over 15 years in the field of global strategy consulting, acting in various leadership positions with a number of research and consulting firms. He has advised senior executives among various industries, including health care, consumer products, and financial services, with a particular focus on enhancing shareholder value. In addition to his consulting background, Ali has direct management experience with several professional service organizations and has been a founding member of startups in both high-technology and business services areas. Ali earned his PhD in Systems Sciences from the Wharton School of the University of Pennsylvania. He also holds an MS in Operations Research from Stanford University, and a BS in Industrial Engineering (summa cum laude) from Syracuse University. He is the author of several articles and co-editor of the book Internal Markets: Bringing the Power of Free Enterprise inside Your Organizations, published by John Wiley & Sons. Ali is married with three children and enjoys skiing, soccer, and squash. He lives in Manhattan.

    
      </div>

      <div className="copyright">© Copyright 2023 UAS Asset Management. All Rights Reserved</div>
      
  </div>
)
};
const JL = () => {return (
  <div>
      <Header/>

      <div className="intro"></div>
      <div className="main_light_left"></div>
      <div className="main_light_left">
      </div>
     <p></p>  <p></p> 
        <div className="intro">
        <b>	James J. Lisanti</b>
        </div>

      <div className="intro">
      Jim manages client services and is responsible for the day-to-day operation of the firm. He joined UAS in May 2006. Jim has logged over 35 years of operations experience in the financial services industry with such firms as Prudential Securities, Munich Re Asset Management, and DLIBJ Asset Management. He served as Vice President and Director of Operations for Donaldson Lufkin Jenrette Asset Management (now part of Credit Suisse Asset Management) for 20 years where he developed and implemented the firm’s operational infrastructure and was directly involved in the creation of operational processes for new products. Jim also served as the Chief Compliance Officer at DLIBJ Asset Management for two years. Jim earned his degree from Villanova University and resides in New Jersey with his wife and daughter.

    
      </div>

      <div className="copyright">© Copyright 2023 UAS Asset Management. All Rights Reserved



</div>  
      
  </div>
)
};

export default App;
export { BMR, AG, JL } ;